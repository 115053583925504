import { Button } from '@/components/ui/button';
import {
  Card,
  CardContent,
  CardDescription,
  CardFooter,
  CardHeader,
  CardTitle,
} from '@/components/ui/card';
import { Input } from '@/components/ui/input';
import { Label } from '@/components/ui/label';
import { useToast } from '@/components/ui/use-toast';
import { VersionDisplay } from '@/components/ui/version-display';
import { authApi } from '@/lib/api';
import { useState } from 'react';
import { Link } from 'react-router-dom';

export function ForgotPasswordForm() {
  const { toast } = useToast();
  const [isLoading, setIsLoading] = useState(false);
  const [email, setEmail] = useState('');
  const [submitted, setSubmitted] = useState(false);

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setIsLoading(true);

    try {
      await authApi.forgotPassword({ email });
      setSubmitted(true);
      toast({
        title: 'Success',
        description: 'If an account exists with this email, you will receive password reset instructions.',
      });
    } catch (error) {
      toast({
        title: 'Error',
        description: error instanceof Error ? error.message : 'Failed to process request',
        variant: 'destructive',
      });
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className='min-h-screen w-full flex flex-col items-center justify-center bg-gray-100'>
      <div className='w-full fixed top-0 left-0 right-0 border-b shadow-sm z-50 bg-white'>
        <div className='max-w-[1400px] mx-auto px-4 md:px-6'>
          <div className='flex justify-center items-center h-16'>
            <div className='flex items-center gap-1'>
              <img src='/android-chrome-512x512.png' alt='Templar Logo' className='w-6 h-6 mr-2' />
              <h1 className='text-xl font-semibold text-gray-900'>Production Panel</h1>
            </div>
          </div>
        </div>
      </div>

      <Card className='w-[350px] mt-16'>
        <form onSubmit={handleSubmit}>
          <CardHeader>
            <CardTitle>Reset Password</CardTitle>
            <CardDescription>
              {submitted 
                ? 'Check your email for reset instructions'
                : 'Enter your email to reset your password'}
            </CardDescription>
          </CardHeader>
          {!submitted && (
            <>
              <CardContent>
                <div className='grid w-full items-center gap-4'>
                  <div className='flex flex-col space-y-1.5'>
                    <Label htmlFor='email'>Email</Label>
                    <Input
                      id='email'
                      type='email'
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                      required
                    />
                  </div>
                </div>
              </CardContent>
              <CardFooter className='flex flex-col gap-4'>
                <Button className='w-full' type='submit' disabled={isLoading}>
                  {isLoading ? 'Sending...' : 'Send Reset Link'}
                </Button>
              </CardFooter>
            </>
          )}
          <CardFooter className='flex justify-center'>
            <Link to="/login" className="text-sm text-primary underline">
              Back to login
            </Link>
          </CardFooter>
        </form>
      </Card>

      <VersionDisplay />
    </div>
  );
} 
import { Button } from '@/components/ui/button';
import {
  Card,
  CardContent,
  CardDescription,
  CardFooter,
  CardHeader,
  CardTitle,
} from '@/components/ui/card';
import { Input } from '@/components/ui/input';
import { Label } from '@/components/ui/label';
import { useToast } from '@/components/ui/use-toast';
import { VersionDisplay } from '@/components/ui/version-display';
import { useAuth } from '@/contexts/AuthContext';
import { authApi } from '@/lib/api';
import Cookies from 'js-cookie';
import { useState } from 'react';
import { useNavigate, Link } from 'react-router-dom';

export function LoginForm() {
  const navigate = useNavigate();
  const { toast } = useToast();
  const { login } = useAuth();
  const [isLoading, setIsLoading] = useState(false);
  const [formData, setFormData] = useState({
    email: '',
    password: '',
  });

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setIsLoading(true);

    try {
      const response = await authApi.login(formData);
      if (response.token) {
        login(response.token);
        toast({
          title: 'Success',
          description: 'Logged in successfully',
        });
        const lastPage = Cookies.get('lastActivePage') || '/dashboard';
        navigate(lastPage, { replace: true });
      } else {
        throw new Error('Invalid response from server');
      }
    } catch (error) {
      toast({
        title: 'Error',
        description: error instanceof Error ? error.message : 'Failed to login',
        variant: 'destructive',
      });
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className='min-h-screen w-full flex flex-col items-center justify-center bg-gray-100'>
      {/* Header */}
      <div className='w-full fixed top-0 left-0 right-0 border-b shadow-sm z-50 bg-white'>
        <div className='max-w-[1400px] mx-auto px-4 md:px-6'>
          <div className='flex justify-center items-center h-16'>
            <div className='flex items-center gap-1'>
              <img src='/android-chrome-512x512.png' alt='Templar Logo' className='w-6 h-6 mr-2' />
              <h1 className='text-xl font-semibold text-gray-900'>Production Panel</h1>
            </div>
          </div>
        </div>
      </div>

      {/* Login Form */}
      <Card className='w-[350px] mt-16'>
        <form onSubmit={handleSubmit}>
          <CardHeader>
            <CardTitle>Login</CardTitle>
            <CardDescription>Enter your credentials to access the panel</CardDescription>
          </CardHeader>
          <CardContent>
            <div className='grid w-full items-center gap-4'>
              <div className='flex flex-col space-y-1.5'>
                <Label htmlFor='email'>Email</Label>
                <Input
                  id='email'
                  type='email'
                  value={formData.email}
                  onChange={(e) => setFormData((prev) => ({ ...prev, email: e.target.value }))}
                  required
                />
              </div>
              <div className='flex flex-col space-y-1.5'>
                <Label htmlFor='password'>Password</Label>
                <Input
                  id='password'
                  type='password'
                  value={formData.password}
                  onChange={(e) => setFormData((prev) => ({ ...prev, password: e.target.value }))}
                  required
                />
              </div>
            </div>
          </CardContent>
          <CardFooter className='flex flex-col gap-4'>
            <Button className='w-full' type='submit' disabled={isLoading}>
              {isLoading ? 'Logging in...' : 'Login'}
            </Button>
            <div className="flex justify-between w-full text-sm text-muted-foreground">
              <Link to="/forgot-password" className="text-primary hover:underline">
                Forgot password?
              </Link>
              <Link to="/register" className="text-primary hover:underline">
                Register instead
              </Link>
            </div>
          </CardFooter>
        </form>
      </Card>

      {/* Version Number */}
      <VersionDisplay />
    </div>
  );
}

import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import { Toaster } from '@/components/ui/toaster';
import { AuthProvider } from '@/contexts/AuthContext';
import { LoginForm } from '@/components/auth/LoginForm';
import { RegisterForm } from '@/components/auth/RegisterForm';
import { ProtectedRoute } from '@/components/auth/ProtectedRoute';
import { Dashboard } from '@/components/Dashboard';
import { PageContainer } from '@/components/layout/PageContainer';
import { LocationProvider } from '@/contexts/LocationContext';
import { ForgotPasswordForm } from './components/auth/ForgotPasswordForm';
import { ResetPasswordForm } from './components/auth/ResetPasswordForm';

function App() {
  return (
    <Router>
      <AuthProvider>
        <LocationProvider>
          <Routes>
            <Route
              path='/login'
              element={
                <PageContainer>
                  <LoginForm />
                </PageContainer>
              }
            />
            <Route
              path='/register'
              element={
                <PageContainer>
                  <RegisterForm />
                </PageContainer>
              }
            />
            <Route path='/forgot-password' element={<ForgotPasswordForm />} />
            <Route path='/reset-password' element={<ResetPasswordForm />} />
            <Route
              path='/dashboard/*'
              element={
                <ProtectedRoute>
                  <PageContainer>
                    <Dashboard />
                  </PageContainer>
                </ProtectedRoute>
              }
            />
            <Route path='/' element={<Navigate to='/dashboard' replace />} />
          </Routes>
          <Toaster />
        </LocationProvider>
      </AuthProvider>
    </Router>
  );
}

export default App;
